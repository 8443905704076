import { Link } from 'react-router-dom';
import logo from '../styles/images/logo.svg';

function Header() {
  return (
    <header>
      <img src={logo} alt="Logo" />
      <div className="spacer"></div>
      <ul className="navigation">
        <li className="selected">
          <Link to="/home">Home</Link>
        </li>
        <li>
          <Link to="/donate">Donate Now</Link>
        </li>
      </ul>
      <div className="church-label">
        St. Theresa's Church
        <div className="sub">Thimbirigasyaya, Colombo 05</div>
      </div>
    </header>
  );
}

export default Header;
