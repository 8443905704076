import React, { useState, useRef } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { DONATE_NOW } from '../Constants';
import { postDonationData } from '../Internals/proxy';

function DonateConfirmation(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentGatewayData, setPaymentGatewayData] = useState({});
  const [paymentGatewayURL, setPaymentGatewayURL] = useState('');
  const formRef = useRef();

  // TODO Show loader when isLoading

  async function postDonationAndInitiatePayment(donationDate) {
    try {
      setIsLoading(true);
      const response = await postDonationData(donationDate);
      setPaymentGatewayData(response.paymentGatewayData);
      setPaymentGatewayURL(response.paymentGatewayURL);
      formRef.current.submit();
    } catch (error) {
      history.push('/error');
    }
  }

  return (
    <div className="content status-page">
      <div className="status-panel confirm-page">
        <h1>Confirm Payment</h1>
        <div className="confirm-data">
          <div className="data-row">
            <label>Full name</label>
            <span>{props.donationDate.name}</span>
          </div>
          <div className="data-row">
            <label>Phone Number</label>
            <span>{props.donationDate.phone}</span>
          </div>
          <div className="data-row">
            <label>Email</label>
            <span>{props.donationDate.email}</span>
            <p>An e-receipt will be sent to the above stated email address.</p>
          </div>
          <div className="donation">
            <h2>Total</h2>
            <h1 className="total">
              Rs.{props.donationDate.amount.toLocaleString('en')}
            </h1>
          </div>
        </div>
        <Button
          className="btn home-btn"
          disabled={isLoading}
          onClick={() => postDonationAndInitiatePayment(props.donationDate)}
        >
          CONFIRM PAYMENT
        </Button>
        <p>
          {/* TODO : fix css for Cancel button  */}
          <Button
            className="btn"
            onClick={() => props.setDonationState(DONATE_NOW)}
          >
            Cancel
          </Button>
        </p>
      </div>
      <div>
        <form
          ref={formRef}
          id="ext-merchant-frm"
          action={paymentGatewayURL}
          method="post"
          acceptCharset="UTF-8"
          encType="application/x-www-form-urlencoded"
        >
          <input
            type="hidden"
            id="merchantPgIdentifier"
            name="merchantPgIdentifier"
            value={paymentGatewayData.merchantPgIdentifier}
          />
          <input
            type="hidden"
            id="chargeTotal"
            name="chargeTotal"
            value={paymentGatewayData.chargeTotal}
          />
          <input
            type="hidden"
            id="currency"
            name="currency"
            value={paymentGatewayData.currency}
          />
          <input
            type="hidden"
            id="paymentMethod"
            name="paymentMethod"
            value={paymentGatewayData.paymentMethod}
          />
          <input
            type="hidden"
            id="orderId"
            name="orderId"
            value={paymentGatewayData.orderId}
          />
          <input
            type="hidden"
            id="invoiceNumber"
            name="invoiceNumber"
            value={paymentGatewayData.invoiceNumber}
          />
          <input
            type="hidden"
            id="successUrl"
            name="successUrl"
            value={paymentGatewayData.successUrl}
          />
          <input
            type="hidden"
            id="errorUrl"
            name="errorUrl"
            value={paymentGatewayData.errorUrl}
          />
          <input
            type="hidden"
            id="storeName"
            name="storeName"
            value={paymentGatewayData.storeName}
          />
          {/* <input type="hidden" id="transactionType" name="transactionType" value=""/> */}
          {/* <input type="hidden" id="timeout" name="timeout" value="120"/> */}
          <input
            type="hidden"
            id="transactionDateTime"
            name="transactionDateTime"
            value={paymentGatewayData.transactionDateTime}
          />
          {/* <input type="hidden" id="language" name="language" value=""/> */}
          <input
            type="hidden"
            id="txnToken"
            name="txnToken"
            value={paymentGatewayData.txnToken}
          />
          {/* <input type="hidden" id="itemList" name="itemList" value=""/>
        <input type="hidden" id="otherInfo" name="otherInfo" value=""/>
        <input type="hidden" id="merchantCustomerPhone" name="merchantCustomerPhone" value=""/>
        <input type="hidden" id="merchantCustomerEmail" name="merchantCustomerEmail" value=""/>
        <input type="hidden" id="disableWebCheckoutQr" name="disableWebCheckoutQr" value=""/>
        <input type="hidden" id="disableWebCheckoutGuest" name="disableWebCheckoutGuest" value=""/>
        <input type="hidden" id="disableWebCheckoutSignIn" name="disableWebCheckoutSignIn" value=""/>
        <input type="hidden" id="discountCode" name="discountCode" value=""/> */}
        </form>
      </div>
    </div>
  );
}

export default DonateConfirmation;
