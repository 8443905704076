import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import './styles/App.scss';
import Header from './components/header';
import Footer from './components/footer';
import HomePage from './pages/Home';
import DonatePage from './pages/Donate';
import DonationStatus from './pages/DonationStatus';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ErrorPage from './pages/ErrorPage';

import { getDonationSummary } from './Internals/proxy';

export const DonationSummaryContext = React.createContext();

const initialContext = {
  donationCount: '0',
  totalCompletedBlocks: '0.00',
  totalReceivedAmount: '0.00',
};

function App() {
  const [donationSummary, setDonationSummary] = useState(initialContext);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  // TODO Show loader when isLoading

  async function getAndUpdateDonationSummary() {
    try {
      const data = await getDonationSummary();
      setDonationSummary(data);
      setIsLoading(false);
    } catch (error) {
      history.push('/error');
    }
  }

  useEffect(() => {
    getAndUpdateDonationSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DonationSummaryContext.Provider
      value={{ donationSummary, getAndUpdateDonationSummary }}
    >
      <div className="wrapper">
        <Header />
        <Switch>
          <Route path="/home" component={HomePage} />
          <Route path="/donate" component={DonatePage} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/status" component={DonationStatus} />
          <Route path="/error" component={ErrorPage} />
          <Route path="/" component={HomePage} />
        </Switch>
        <Footer />
      </div>
    </DonationSummaryContext.Provider>
  );
}

export default App;
