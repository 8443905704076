function PrivacyPolicy() {
  return (
    <div className="content">
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>
          Welcome to St Theresa’s Church in Colombo, Sri Lanka. At St Theresa's,
          one of our main priorities is safeguarding the privacy of our
          worshippers. This privacy policy provides an overview of our privacy
          practices and applies to our website, mobile application, and all St
          Theresa’s Church accounts on social media platforms (collectively,
          known as “our services”). If you have additional questions or require
          more information about our Privacy Policy, please do not hesitate to
          contact us.
        </p>
        <p>
          <h3>Consent</h3> By using our website, you hereby consent to our
          Privacy Policy and agree to its terms. This Privacy Policy is
          incorporated into, and is a part of, St Theresa’s Church website Terms
          and Conditions, which govern your use of this site in general, and
          shall be read as if the Terms and Conditions and Privacy are one
          unified document.
          <h3>Gathering of Information</h3>
          <h4>1. Information you provide</h4>
          to us - In order to use most aspects of the site, you are not required
          to provide any information about yourself. However, we may ask or
          require you to provide certain information, such as your name,
          address, contact number and e-mail address, if you choose to request
          information, request for prayers, wish to volunteer or make a
          donation. We may ask for or require this information so that we can
          respond to inquiries or communications from you or for other purposes.
          <h4>2. Information that is collected automatically</h4>Our services
          may collect some information automatically about your device. For
          example, the site may collect your Internet Protocol or “IP” address,
          information on which web browser software you are using, mobile device
          identifiers, and the web site that referred you to our site. It also
          may collect information about your activity on the site, such as which
          pages you choose to view. This information helps us to make the site
          more useful for you and our other visitors. We also may obtain
          geolocation information when you access our services from a mobile
          device.
          <h4>3. Social Networks</h4>If you choose to access or use
          social-networking services (such as Facebook. Instagram, YouTube or
          Twitter), we may receive personal information about you that you have
          made available to those services, including information about your
          contacts on those services. Any personal information we receive
          through social-networking services will be governed by this policy.
          <h4>4. Cookies and Other Technologies.</h4>The site may assign your
          computer a “cookie.” A “cookie” is a small text file that resides on
          your computer and helps our site remember that your computer has
          visited the site before and to identify which pages have been viewed
          when you have visited the site. We also may configure cookies to
          include other information, such as the website address of a page that
          linked to our site, if you arrived at our site by following a link. We
          use cookies, as do most websites, to make the site more useful and
          convenient for our users. You may opt out of receiving cookies
          delivered through the site simply by changing the appropriate setting
          on your browser and it will generally not interfere with your use of
          our services. We also may use other similar technologies to improve
          our services.
          <h4>5. Printed</h4>
          and Non-Web Publications. Information provided for certain St
          Theresa’s publications and communications media, including the weekly
          bulletin that is distributed in paper form at Mass and other types of
          communications, may be posted on our website. When you provide
          personal information to be included in our bulletin or other
          publications (as contact information for activities or programs, for
          example), that information also may be accessible on this site.
        </p>
        <p>
          <h3>Use of Information</h3> We may use the information we collect
          about you in connection with the site for several reasons, including:
          <br />
          <br />
          • Provide, operate, and maintain our website • Improve, personalize,
          and expand our website
          <br /> • Understand and analyze how you use our website
          <br /> • Develop new products, services, features, and functionality
          <br />
          • Communicate with you, to provide you with updates and other
          information relating to the website, and for other spiritual and
          evangelical support purposes.
          <br /> • Send you emails
          <br /> • Find and prevent fraud Our site also permits you to make
          online donations. When you make online donations, your information is
          kept confidential and can only be used by us to contact you to respond
          to questions or to notify you of any issues pertaining to your
          donation. All our fundraising procedures adhere to electronic commerce
          regulations and the consumer rights act.
        </p>
        <p>
          <h3>Sharing of Information</h3> We generally will not share the
          information that we collect about you with third parties. However, we
          may share this information with third parties in these limited
          circumstances: <br />
          <br />
          • to respond to a question or inquiry received from you or to provide
          you with information that you request; <br />
          • with our authorized service providers that perform certain functions
          or services on our behalf (such as to host or operate the site or our
          other services, to operate our online donation features, to manage our
          databases, and to conduct analyses of site usage); <br />
          • to respond to legal process, in response to a request for
          cooperation from law enforcement or another government agency, to
          investigate, prevent, or take action regarding illegal activities,
          suspected fraud, or to enforce our Terms of Use or protect our rights
          or the rights of others; <br />
          • in aggregated, anonymized or another non-personally identifiable
          form to third parties for various purposes, including to improve the
          quality of our services, the site, and the services we offer; and{' '}
          <br />• as otherwise described to you at the point of collection or
          pursuant to your consent.
        </p>
        <p>
          <h3>Contributions, user-generated content and external links</h3> We
          may use a third-party payment vendor to process contributions made
          through the site. When you make a contribution through the services
          provided by this vendor, you are providing information to the vendor,
          which operates under its own privacy policy rather than this Privacy
          Policy. We have also put reasonable procedures in place to safeguard
          the information we collect from you. However, please note that
          although we take reasonable steps to protect your information, no
          website, Internet transmission, computer system or wireless connection
          is completely secure.
        </p>
        <p>
          Our services may contain interactive areas where users might submit
          comments or other content (including information about the user that
          might be displayed in the user’s public profile within an online
          community) that may be posted on a service or otherwise published. If
          you use these features, please be aware that if you voluntarily
          disclose personal information (such as name, e- mail address, and the
          like) in content submitted for posting or publication, the information
          can be read and used by others. Similarly, links from this site may
          lead you to third-party websites. Once you click the links to these
          3rd party websites and leave our website, we are not responsible for
          the protection and privacy of any information you may disclose to the
          3rd party website.
        </p>
        <p>
          <h3>Security Practices and Information Accuracy</h3> We maintain one
          or more databases to store your Personal Information and may retain it
          as reasonably required to serve you, run our activities, and comply
          with our legal obligations. We have implemented reasonable safeguards
          designed to protect your information from loss, misuse, alteration or
          destruction. We also take reasonable steps designed to ensure that
          third parties who work with us agree to protect the Personal
          Information.
        </p>
        <p>
          <h3>Changes to this Privacy Policy</h3> We may update this Privacy
          Policy over time to reflect changes in how we manage information or
          changes in the functionality of our site. We will notify you about
          material changes to this Privacy Policy by placing the updated Privacy
          Policy on the site and/or contacting you using any contact information
          that you have provided us, as appropriate for the nature of the
          changes made, and we will indicate the date on which the Privacy
          Policy was most recently updated. Your continued use of the site after
          we modify the Privacy Policy indicates that you accept these changes
          and is governed by the practices described in the updated Privacy
          Policy. We encourage you to periodically review this page for the
          latest information on our privacy practices.
        </p>
        <p>
          <h3>Access to your information</h3> You can access and update the
          personal information you have provided on our website by contacting us
          at the address listed below. You can also forward your complaints,
          questions or queries if any, about our use of your personal data to
          the same address. We will investigate all complaints received and will
          endeavor to respond to complaints promptly.
        </p>
        <p>
          St Theresa’s Church
          <br /> 364, Thimbirigasyaya Road, <br />
          Colombo 05, Sri Lanka. <br />
          +94112583425 <br />
          parish.office@sttc.lk <br />
          <h4>January 2021</h4>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
