import React from 'react';
import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ErrorMessageFooter from '../components/ErrorMessageFooter';

function ErrorPage() {
  const mainURL = window.location.protocol + '//' + window.location.host;

  return (
    <div className={'content status-page'}>
      <div className="status-panel">
        <h1>
          <ExclamationCircleOutlined /> Sorry...
        </h1>
        <p className="desc">
          Looks like there is a network issue.
          <p>
            <strong>Please reload load the web page and try again.</strong>
          </p>
          <Button
            className="btn home-btn"
            onClick={() => window.location.replace(mainURL)}
          >
            Reload
          </Button>
        </p>
        <ErrorMessageFooter />
      </div>
    </div>
  );
}

export default ErrorPage;
