import { BASE_URL } from '../config';

export async function getDonationSummary() {
  const URL = `${BASE_URL}/v1/charity/donations/summary`;
  const response = await fetch(URL);
  const data = await response.json();
  return data.data;
}

export async function postDonationData(data) {
  const URL = `${BASE_URL}/v1/charity/donations`;
  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
      blockCount: Math.round((data.blockCount + Number.EPSILON) * 100) / 100,
    }),
  });
  const responseData = await response.json();
  return responseData.data;
}

export async function validatePaymentAndUpdateDonation(data) {
  const URL = `${BASE_URL}/v1/charity/donations/${data.transactionRawData.invoice_number}`;
  const response = await fetch(URL, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  return responseData;
}
