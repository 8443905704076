import React, { useRef, useState } from 'react';
import { Carousel } from 'antd';

import Imageside1 from '../styles/images/gallery/mainslider/smileproject.jpg';
import Imageside2 from '../styles/images/gallery/mainslider/pensionscheme.jpg';
import Imageside3 from '../styles/images/gallery/mainslider/mealsonwheels.jpg';

function CommonSlider() {
  const slider = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  function onSlideChange(current) {
    if (slideIndex !== current) {
      setSlideIndex(current);
    }
  }
  function MediaSlide(url) {
    return (
      <div key={url}>
        <div
          className="media-slide"
          style={{ backgroundImage: 'url(' + url + ')' }}
        ></div>
      </div>
    );
  }

  function Thumbnail(url, index) {
    return (
      <div
        key={url}
        className={`media-slide-thumbnail ${
          index === slideIndex && 'selected'
        }`}
        style={{ backgroundImage: 'url(' + url + ')' }}
        onClick={() => {
          setSlideIndex(index);
          slider.current.goTo(index);
        }}
      ></div>
    );
  }
  const mediaSlides = [Imageside1, Imageside2, Imageside3];

  const thumbnails = [Imageside1, Imageside2, Imageside3];
  return (
    <div className="media-column">
      <Carousel
        afterChange={onSlideChange}
        effect="fade"
        autoplay={true}
        ref={(ref) => {
          slider.current = ref;
        }}
      >
        {mediaSlides.map((slide) => MediaSlide(slide))}
      </Carousel>
      <div className="thumbnails">
        {thumbnails.map((thumbnail, index) => Thumbnail(thumbnail, index))}
      </div>
    </div>
  );
}

export default CommonSlider;
