import React, { useState, useEffect, useContext } from "react";
import { Button } from "antd";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DonationSummaryContext } from "../App";
import ErrorMessageFooter from "../components/ErrorMessageFooter";

import { validatePaymentAndUpdateDonation } from "../Internals/proxy";
import {
	TRANSACTION_VALIDATING,
	TRANSACTION_SUCCESS,
	TRANSACTION_FAILED,
} from "../Constants";

function DonatePage() {
	const history = useHistory();
	const { getAndUpdateDonationSummary } = useContext(DonationSummaryContext);
	const [paymentStatus, setPaymentStatus] = useState(TRANSACTION_VALIDATING);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationStatus, setConfirmationStatus] = useState("");

	async function validateAndUpdatePaymentStatus() {
		try {
			setConfirmationMessage("");
			setPaymentStatus(TRANSACTION_VALIDATING);
			const search = window.location.search.substring(1);
			const responseDate = JSON.parse(
				'{"' +
					decodeURI(search)
						.replace(/"/g, '\\"')
						.replace(/&/g, '","')
						.replace(/=/g, '":"') +
					'"}'
			);
			const data = await validatePaymentAndUpdateDonation({
				statusCode: responseDate.code,
				paymentReference: responseDate.trx_ref_number || "-",
				status: responseDate.status,
				transactionTimeStamp: responseDate.trx_date_time,
				amount: responseDate.charge_total,
				transactionToken: responseDate.txn_token,
				transactionRawData: responseDate,
			});
			setConfirmationMessage(data.data.message);
			setConfirmationStatus(data.status);
			if (data.status === "success") {
				setPaymentStatus(TRANSACTION_SUCCESS);
			} else {
				setPaymentStatus(TRANSACTION_FAILED);
			}
		} catch (error) {
			setPaymentStatus(TRANSACTION_FAILED);
		} finally {
			getAndUpdateDonationSummary();
		}
	}

	useEffect(() => {
		validateAndUpdatePaymentStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={"content status-page"}>
			<div
				className={`status-panel ${
					paymentStatus === TRANSACTION_VALIDATING && "hide"
				}`}>
				<h1>Your payment is being processed... </h1>
				<p className="desc">
					<Spin size="large" />
					<p>
						<strong>Please do not refresh or reload your browser</strong>
					</p>
				</p>
				<ErrorMessageFooter />
			</div>

			{/* <div
				className={`status-panel ${
					paymentStatus !== TRANSACTION_SUCCESS && "hide"
				}`}>
				<h1>Thank you for you generous donation!</h1>
				<p className="desc">
					Please contact us if you require further clarification regarding this
					project.
					<p>
						<strong>Please check your inbox for your donation receipt.</strong>
					</p>
					<Button
						className="btn home-btn"
						onClick={() => history.push("/home")}>
						Go to Home Page
					</Button>
					<p>
						<a target="_BLANK" rel="noreferrer" href="https://sttc.lk/">
							Go to Church Website
						</a>
					</p>
				</p>
				<ErrorMessageFooter />
			</div> */}

			{/* <div
				className={`status-panel ${
					paymentStatus !== TRANSACTION_FAILED && "hide"
				}`}>
				<h1>
					<ExclamationCircleOutlined /> Donation Failed
				</h1>

				<p className="desc">
					<div>
						{confirmationMessage
							? confirmationMessage
							: "We were unable to process your payment. This may be due to a technical issue or payment method error. Please check your payment method and try again."}
					</div>

					{confirmationStatus !== "confirmationFailed" && (
						<Button
							className="btn home-btn"
							onClick={() => history.push("/donate")}>
							Retry Donation
						</Button>
					)}

					<div>
						<Link to="/home">Go to home page</Link>
					</div>
				</p>
				<ErrorMessageFooter />
			</div> */}

			<div className="status-panel">
				<h1>Thank you for your donation!</h1>
				<p className="desc">
					Please contact us if you require further clarification regarding this
					project.
					<Button
						className="btn home-btn"
						onClick={() => history.push("/home")}>
						Go to Home Page
					</Button>
					<p>
						<a target="_BLANK" rel="noreferrer" href="https://sttc.lk/">
							Go to Church Website
						</a>
					</p>
				</p>
				<ErrorMessageFooter />
			</div>

		</div>
	);
}

export default DonatePage;
