/* eslint-disable no-template-curly-in-string */
import { useState } from 'react';
import { Button, Form, Input, InputNumber, Radio } from 'antd';
import { ANY_AMOUNT, BLOCKS, BLOCK_PRICE } from '../Constants';
import Genielogos from '../styles/images/genielogos.png';
import LockIcon from '../styles/images/lock.svg';

function DonationForm(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  return (
    <div className="info-column donation-form">
      <h1 className="title">
        Donation Details
        <div className="spacer"></div>
        <Button
          type="primary"
          className="btn orange-action-btn currency-btn"
          // onClick={showModal}
        >
          <a
            target="_BLANK"
            rel="noreferrer"
            href="https://www1.oanda.com/currency/converter/"
          >
            {' '}
            Currency Convertor
          </a>
        </Button>
        {/* <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleOk}
          okButtonText="Close"
          okButtonProps={{ className: "btn orange-action-btn" }}
          cancelButtonProps={{ className: "hide" }}>
          <div className="currency-table">
            <h2>
              Price Per Block - Rs. {BLOCK_PRICE.toLocaleString("en")} (LKR)
            </h2>
            <ul>
              <li>
                <div className="label">USD</div> <strong>51.94</strong>
              </li>
              <li>
                <div className="label">AUD </div>
                <strong>67.80</strong>
              </li>
              <li>
                <div className="label">GBP </div>
                <strong>37.90</strong>
              </li>
              <li>
                <div className="label">EURO</div> <strong>43.18</strong>
              </li>
              <li>
                <div className="label">AED</div> <strong>190.77</strong>
              </li>
            </ul>
            <p>Rates updated February 8 2021</p>
            <p>
              For further currency calculations{" "}
              <a
                target="_BLANK"
                rel="noreferrer"
                href="https://www1.oanda.com/currency/converter/">
                Click here
              </a>
            </p>
          </div>
        </Modal> */}
      </h1>
      <Form
        name="nest-messages"
        onFinish={props.onDonateNowFinish}
        validateMessages={validateMessages}
      >
        <Form.Item label="Donation Type">
          <Radio.Group
            defaultValue={props.donationType}
            onChange={props.onDonationTypeChange}
          >
            <Radio.Button value={ANY_AMOUNT} className="anybtn">
              <div className="icon"></div>ANY AMOUNT
            </Radio.Button>
            <Radio.Button value={BLOCKS} className="blockbtn">
              <div className="icon"></div>FAMILIES
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {props.donationType === BLOCKS && (
          <Form.Item label="Number of Families" rules={[{}]}>
            <InputNumber
              min={1}
              defaultValue={props.blockCount}
              value={props.blockCount}
              onChange={props.onNoOfBlocksChange}
            />
            <span className="amount-label">
              Donation per family would be{' '}
              <strong>Rs.{BLOCK_PRICE.toLocaleString('en')}</strong>
            </span>
          </Form.Item>
        )}

        {props.donationType === ANY_AMOUNT && (
          <Form.Item
            // name={['user', 'anyAmountSelectValue']}
            label="Donation Amount"
          >
            <Radio.Group
              onChange={props.onChangeAnyAmount}
              value={props.anyAmountSelectValue}
              className="any-amount-row"
            >
              <Radio value={1000}>Rs. 1,000</Radio>
              <Radio value={3000}>Rs. 3,000</Radio>
              <Radio value={5000}>Rs. 5,000</Radio>
              <Radio value="other">
                Other
                {props.anyAmountSelectValue === 'other' && (
                  <InputNumber
                    min={1000}
                    defaultValue={props.donationAmount}
                    value={props.donationAmount}
                    onChange={(amount) => {
                      props.setBlockCount(amount / BLOCK_PRICE);
                      props.setDonationAmount(amount);
                    }}
                    style={{ width: 100, marginLeft: 10 }}
                  />
                )}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          name={['user', 'name']}
          label="Full name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['user', 'email']}
          label="Email Address"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <div style={{ marginTop: -10, marginBottom: 10 }}>
          Please enter an email address to receive an e-receipt
        </div>

        <Form.Item
          name={['user', 'phone']}
          label="Phone Number"
          rules={[
            { required: true, message: 'Please input your phone number!' },
          ]}
        >
          {/* <Input addonBefore={prefixSelector} style={{width: '100%'}} /> */}
          <Input style={{ width: '100%' }} />
        </Form.Item>
        {/* <div className="selected-floor">
          <label>
            Selected Floor <strong>{props.slideIndex + 1}</strong>
          </label>
        </div> */}
        <div className="donation">
          <h2>Total</h2>
          <h1 className="total">
            Rs.{props.donationAmount.toLocaleString('en')}
          </h1>
        </div>

        <div className="donation">
          <div className="donate">
            <Form.Item>
              <Button className="btn donate-btn" htmlType="submit">
                DONATE NOW
              </Button>
              <div className="btn-note">
                Secure payments via <strong>Dialog Genie</strong>
              </div>
            </Form.Item>
            <div className="security-notice aligned">
              <img src={LockIcon} alt="Icon" className="lockicon" />
              <p>
                Note: All donations are kept strictly confidential and all
                measures have been taken to secure your online transactions.
                <div>
                  <img src={Genielogos} alt="Logo" className="genielogos" />
                </div>
              </p>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default DonationForm;
