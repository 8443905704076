function ErrorMessageFooter() {
  return (
    <p>
      If you encounter any issues or have any queries please call
      <strong>+94 715 220 222 / +94 112 583 425</strong> or email{' '}
      <strong>donations@sttc.lk</strong>
    </p>
  );
}

export default ErrorMessageFooter;
