import React, { useRef, useState, useContext } from 'react';
import { Progress, Button, Carousel } from 'antd';
import { useHistory } from 'react-router-dom';
import LockIcon from '../styles/images/lock.svg';

import Imageside1 from '../styles/images/gallery/mainslider/smileproject.jpg';
import Imageside2 from '../styles/images/gallery/mainslider/pensionscheme.jpg';
import Imageside3 from '../styles/images/gallery/mainslider/mealsonwheels.jpg';

// import { REQUIRED_DONATION } from "../Constants";

import { DonationSummaryContext } from '../App';
import CommonSlider from '../components/CommonSlider';

function HomePage() {
  const { donationSummary } = useContext(DonationSummaryContext);
  const currentDonation = donationSummary?.totalReceivedAmount;
  const history = useHistory();
  const slider = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  function onSlideChange(current) {
    if (slideIndex !== current) {
      setSlideIndex(current);
    }
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function MediaSlide(url) {
    return (
      <div key={url}>
        <div
          className="media-slide"
          style={{ backgroundImage: 'url(' + url + ')' }}
        ></div>
      </div>
    );
  }

  function Thumbnail(url, index) {
    return (
      <div
        key={url}
        className={`media-slide-thumbnail ${
          index === slideIndex && 'selected'
        }`}
        style={{ backgroundImage: 'url(' + url + ')' }}
        onClick={() => {
          setSlideIndex(index);
          slider.current.goTo(index);
        }}
      ></div>
    );
  }
  const mediaSlides = [Imageside1, Imageside2, Imageside3];

  const thumbnails = [Imageside1, Imageside2, Imageside3];
  return (
    <div className="content donate-home">
      <CommonSlider />
      <div className="info-column">
        <h1>Dear Friends,</h1>
        <p>
          St Theresa’s Church has always been identified not only as a religious
          haven but also as a strong community partner in times of need. Thanks
          to your generosity, many a times we have been able to support the
          community within and beyond our parish boundaries, especially in times
          of distress and adversity.
        </p>
        <p>
          As you are aware, the current situation of the country has curtailed
          the income of many people and the rise of cost of living has made it
          almost impossible for the poor and needy to afford their daily bread.
        </p>
        <p>
          Therefore we have initiated a fundraising project to support affected
          families with dry rations and other basic provisions to help them cope
          during this difficult time.
        </p>
        <p>
          Now more than ever it is time for St Theresa’s faith community to
          reach out and provide possible assistance to all our needy brothers
          and sisters, regardless of background, ethnicity or faith.
        </p>
        <p>
          As St Therese, our patron saint says, “Remember nothing is small in
          the eyes of God. Do all that you do with love”. Therefore let's unite
          to serve our less fortunate brothers and sisters with true love and
          charity to make a positive difference in their lives.
        </p>

        <p>May God bless you and your family.</p>
        <p>
          Yours in the Redeemer. <br />
          <strong>
            Fr. Chandana Sanjeewa Perera, C.Ss.R. <br />
            Parish Priest.
          </strong>
        </p>

        <h3>
          For more information call{' '}
          <strong>Sylvester on +94 715 220 222 / +94 112 583 425</strong> or
          email <strong> donations@sttc.lk</strong>
        </h3>
        <div className="donation">
          {/*<div className="connector"> </div>
          <div className="donation-progress">
						<div className="donation-progress-header">
							<div className="left">Current Donations</div>
							<div className="right">Total Estimated Required</div>
						</div>
						<Progress
              strokeWidth={'1.3rem'}
              strokeColor={{
                '0%': '#E6C65A',
                '100%': '#E6A176',
              }}
              percent={Math.round((currentDonation / REQUIRED_DONATION) * 100)}
            />
						<div className="donation-progress-values">
							<div className="left">
								Rs.{numberWithCommas(currentDonation?.toLocaleString("en"))}
							</div>
							<div className="right">
								Rs.{REQUIRED_DONATION?.toLocaleString("en")}
							</div>
						</div>
					</div> */}
          <div className="donate">
            <Button
              className="btn donate-btn"
              onClick={() => history.push('/donate')}
            >
              DONATE NOW
            </Button>
            <div className="security-notice">
              <img src={LockIcon} alt="Icon" />
              <p>
                Note: All donations are kept strictly confidential and all
                measures have been taken to secure your online transactions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
