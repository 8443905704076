import { Link } from 'react-router-dom';
import Syscolabslogo from '../styles/images/sysco-labs-logo.svg';

function Footer() {
  return (
    <footer>
      <span>
        Copyright © {new Date().getFullYear()} St. Theresa’s Church
        Thimbirigasaya
      </span>
      <ul className="footer-links">
        <li>
          <Link to="/privacy">Legal Terms and Privacy Policy</Link>
        </li>
        {/* <li>
          <a arget="_BLANK" href="#">Contact</a>
        </li> */}
        <li>
          <a target="_BLANK" rel="noreferrer" href="https://sttc.lk/">
            Go to Church Website
          </a>
        </li>
      </ul>
      <div className="contact-label">
        For more information call{' '}
        <strong>Sylvester on +94 715 220 222 / +94 112 583 425</strong> or email{' '}
        <strong> donations@sttc.lk</strong>
      </div>
      <div className="created-by">
        Created by
        <a target="_BLANK" rel="noreferrer" href="https://syscolabs.lk/">
          <img src={Syscolabslogo} alt="Logo" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
//
