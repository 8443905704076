import React, { useState, useContext } from 'react';
import {
  ANY_AMOUNT,
  BLOCKS,
  BLOCK_PRICE,
  DONATE_NOW,
  CONFIRM_DONATION,
} from '../Constants';
import DonationForm from '../components/DonationForm';
import DonateConfirmation from '../components/DonationConfirmation';

import { DonationSummaryContext } from '../App';
import CommonSlider from '../components/CommonSlider';

function DonatePage() {
  const { donationSummary } = useContext(DonationSummaryContext);

  const [slideIndex, setSlideIndex] = useState(0);
  const [anyAmountSelectValue, setAnyAmountSelectValue] = useState(1000);

  const [donationType, setDonationType] = useState(ANY_AMOUNT);
  const [donationAmount, setDonationAmount] = useState(1000);
  const [blockCount, setBlockCount] = useState(1000 / BLOCK_PRICE);
  const [donationState, setDonationState] = useState(DONATE_NOW);
  const [donationDate, setDonationData] = useState({});

  function onNoOfBlocksChange(blockCount) {
    setBlockCount(blockCount);
    setDonationAmount(blockCount * BLOCK_PRICE);
  }

  function onDonationTypeChange(event) {
    const donationType = event.target.value;
    setDonationType(donationType);
    if (donationType === BLOCKS) {
      setBlockCount(1);
      setDonationAmount(BLOCK_PRICE);
    } else {
      setAnyAmountSelectValue(1000);
      setDonationAmount(1000);
      setBlockCount(1000 / BLOCK_PRICE);
    }
  }

  const onDonateNowFinish = (values) => {
    const data = {
      donationType,
      amount: donationAmount,
      blockCount,
      // floor: slideIndex + 1,
      ...values.user,
    };
    setDonationData(data);
    setDonationState(CONFIRM_DONATION);
  };

  const onChangeAnyAmount = (e) => {
    const { value } = e.target;
    setAnyAmountSelectValue(value);
    if (value !== 'other') {
      setDonationAmount(value);
      setBlockCount(value / BLOCK_PRICE);
    } else {
      setDonationAmount(1000);
      setBlockCount(1000 / BLOCK_PRICE);
    }
  };

  function getFloors() {
    const totalCompletedBlocks = Math.round(
      donationSummary?.totalCompletedBlocks
    );
    const requiredBlocksForFloor1 = 2029;
    const requiredBlocksForFloor2 = 2029;
    const requiredBlocksForFloor3 = 2029;
    const floors = [
      {
        floor: 1,
        noOfBlocks: requiredBlocksForFloor1,
        donatedBlocks: Math.min(totalCompletedBlocks, requiredBlocksForFloor1),
      },
      {
        floor: 2,
        noOfBlocks: requiredBlocksForFloor2,
        donatedBlocks: Math.min(
          Math.max(totalCompletedBlocks - requiredBlocksForFloor1, 0),
          requiredBlocksForFloor2
        ),
      },
      {
        floor: 3,
        noOfBlocks: requiredBlocksForFloor3,
        donatedBlocks: Math.min(
          Math.max(
            totalCompletedBlocks -
              (requiredBlocksForFloor1 + requiredBlocksForFloor2),
            0
          ),
          requiredBlocksForFloor3
        ),
      },
    ];

    return floors;
  }

  return (
    <div className="content">
      {donationState === DONATE_NOW ? (
        <>
          <CommonSlider />
          <DonationForm
            onDonateNowFinish={onDonateNowFinish}
            donationType={donationType}
            onDonationTypeChange={onDonationTypeChange}
            blockCount={blockCount}
            onNoOfBlocksChange={onNoOfBlocksChange}
            onChangeAnyAmount={onChangeAnyAmount}
            anyAmountSelectValue={anyAmountSelectValue}
            donationAmount={donationAmount}
            setBlockCount={setBlockCount}
            setDonationAmount={setDonationAmount}
            slideIndex={slideIndex}
          />
        </>
      ) : (
        <DonateConfirmation
          setDonationState={setDonationState}
          donationDate={donationDate}
        />
      )}
    </div>
  );
}

export default DonatePage;
