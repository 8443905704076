export const REQUIRED_DONATION = 60866138;

// Donation Types
export const ANY_AMOUNT = 'ANY_AMOUNT';
export const BLOCKS = 'BLOCKS';

// Donation State
export const DONATE_NOW = 'DONATE_NOW';
export const CONFIRM_DONATION = 'CONFIRM_DONATION';

// Transaction status
export const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS';
export const TRANSACTION_FAILED = 'TRANSACTION_FAILED';
export const TRANSACTION_VALIDATING = 'TRANSACTION_VALIDATING';

export const BLOCK_PRICE = 10000;
